import * as React from 'react';

import { IQuickLinks } from '../../models/content.interfaces';

import { Container, List } from './QuickLinks.styles';
import { QuickLink } from './QuickLink';

export const QuickLinks = ({ items }: IQuickLinks) => (
  <Container>
    <List aria-label="quick-links" data-id="quick-links">
      {items && items.map((item) => <QuickLink {...item} key={item.label} />)}
    </List>
  </Container>
);

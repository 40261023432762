import React from 'react';

import { IHeading } from 'src/models/content.interfaces';
import { Heading } from '@origin-digital/ods-core';

export const SectionHeading = ({ heading }: IHeading) => (
  <Heading variant="h3" component="h2" weight="regular" align="center">
    {heading}
  </Heading>
);

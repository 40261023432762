import React from 'react';

import { Heading, Stack } from '@origin-digital/ods-core';

import { BREAK_POINTS } from '../../consts/style';
import { IOffers } from '../../models/content.interfaces';
import { SectionSidePadding } from '../Common';

import { Slider, Slide } from '../Common/Slider';
import { Offer } from './Offer';
import { OffersWrapper } from './Offers.styles';

export const Offers = ({ items, sectionHeading }: IOffers) => {
  const validOffers = items.filter((item) => item.heading !== '');

  const swiperProps = {
    'data-id': 'swiper--with-scrollbar',
    'aria-label': 'offers',
    slidesOffsetAfter: 16,
    slidesOffsetBefore: 16,
    slidesPerView: 1.2,
    spaceBetween: 16,
    keyboard: {
      enabled: true,
    },
    scrollbar: {
      container: '.swiper-scrollbar',
      draggable: true,
      snapOnRelease: true,
    },
    breakpoints: {
      [BREAK_POINTS.Min.SM.slice(0, 3)]: {
        slidesPerView: 1.6,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      [BREAK_POINTS.Min.MD.slice(0, 3)]: {
        slidesPerView: 2.2,
        spaceBetween: 32,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      [BREAK_POINTS.Min.LG.slice(0, 3)]: {
        slidesPerView: 2.6,
        spaceBetween: 32,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      [BREAK_POINTS.Min.XL.slice(0, 4)]: {
        slidesPerView: 3,
        spaceBetween: 32,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
    },
  };

  return (
    <OffersWrapper>
      <Stack
        space={{
          lg: 'xxlarge',
          xs: 'large',
        }}
      >
        <SectionSidePadding>
          <Heading variant="h3" component="h2" weight="regular" align="center">
            {sectionHeading}
          </Heading>
        </SectionSidePadding>

        <SectionSidePadding variant="hide_gutter_xs">
          <Slider id="offers" swiperProps={swiperProps}>
            <>
              {validOffers &&
                validOffers.map((item) => (
                  <Slide key={item.heading}>
                    <Offer {...item} />
                  </Slide>
                ))}
            </>
          </Slider>
        </SectionSidePadding>
      </Stack>
    </OffersWrapper>
  );
};

import { IContentCardHeroBanner } from '@origin-digital/event-dispatcher';

// That apostophe you’re always looking for is here: ’

export const APPSTORE_URL = 'https://m.origin.com.au/fo2UErMINgb';

export const APP = {
  heading: 'An app built for bill-savvy folk, just like you',
  description: ['Make one-tap payments', 'Manage your services', 'Access offers & rewards'],
  image: 'homepage/home_app_2x.png',
  label: 'Download the Origin App',
  url: APPSTORE_URL,
};

export const BANNER: IContentCardHeroBanner = {
  type: 'heroBanner',
  rank: 1,
  location: 'homePage-heroBanner',
  experienceId: 'home-banner-default - useful launch 2025',
  hasPostcode: false,
  image: {
    cloudinaryPath: 'homepage/homepage_hero_xl_2x.png',
    altText: 'See the useful',
  },
  imageMobile: {
    cloudinaryPath: 'homepage/homepage_hero_xs_2x.png',
  },
  title: 'All Kinds of Useful',
  body: 'The relief of things being just a bit easier. Find out how we’re making it happen, every day, in all kinds of ways.',
  ctaContained: {
    label: 'See the useful',
    action: '/useful/',
  },
  ctaOutlined: {
    label: '',
    action: '',
  },
};

export const OFFERS = {
  sectionHeading: 'Explore our latest offers on energy, solar & internet',
  items: [
    {
      category: '',
      tag: {
        text: 'Useful',
        colour: 'promoteD',
      },
      description: 'We’re making useful happen every day, in all kinds of ways.',
      heading: 'All kinds of \nuseful',
      image: '/homepage/Offers/all-kinds-of-useful.png',
      imageAltText: 'All kinds of useful',
      label: 'See the useful',
      accessibleLabel: '',
      url: '/useful',
      theme: 'orange',
    },
    {
      category: 'Solar',
      description: '',
      heading: 'Discover great deals on solar',
      image: 'homepage/Offers/home_offer-solar.svg',
      imageAltText: 'Get a free custom quote',
      label: 'Get a free custom quote',
      accessibleLabel: '',
      url: '/solar/quote/',
      theme: 'yellow',
    },
    {
      category: 'Internet',
      description: 'Reliable, no lock-in plans with more bang for your buck',
      heading: 'Award-winning internet',
      image: 'homepage/Offers/home_offer_internet.svg',
      imageAltText: 'Award-winning internet',
      label: 'View internet plans',
      accessibleLabel: '',
      url: '/internet/plans/',
      theme: 'purple',
    },
    {
      category: 'LPG bottled gas',
      description:
        'Get real-time updates so you can track your LPG bottled gas delivery or exchange.',
      heading: 'Door to door delivery',
      image: 'homepage/Offers/home_offer_lpg.svg',
      imageAltText: '',
      label: 'Get a price',
      accessibleLabel: 'View LPG bottle gas offer',
      url: '/lpg/offers',
      theme: 'light-orange',
    },
    {
      category: 'Virtual Power Plant',
      description: 'Join an intelligent network of connected solar batteries with Origin Loop.',
      heading: 'Discover the new energy grid',
      image: 'homepage/Offers/home_offer_vpp.svg',
      imageAltText: '',
      label: 'Learn more',
      accessibleLabel: "Learn more about Origin's energy grid",
      url: '/solar/panels-batteries/virtual-power-plant/',
      theme: 'pink',
    },
    {
      category: 'Energy',
      description:
        "Life's full of the unexpected. Get an energy plan backed by Allianz Global Assistance.",
      heading: 'Energy + 24 hour support',
      image: 'homepage/Offers/home_offer_home-support.svg',
      imageAltText: '',
      label: 'View plan',
      accessibleLabel: 'View emergency assist energy plan',
      url: '/for-home/electricity-and-gas/plans/home-support/',
      theme: 'yellow',
    },
  ],
};

export const PRODUCT_QUICK_LINKS = {
  sectionHeading: 'What services can we help you with today?',
  items: [
    {
      url: '/electricity-gas/plans.html',
      label: 'Electricity \n& gas plans',
      iconName: 'Electricity',
      newTab: false,
    },
    {
      url: '/internet/plans/',
      label: 'Internet \nplans',
      iconName: 'Internet',
      newTab: false,
    },
    {
      url: '/solar/',
      label: 'Solar & batteries',
      iconName: 'Solar',
      newTab: false,
    },
    {
      url: '/lpg/',
      label: 'LPG \nbottles',
      iconName: 'Lpg',
      newTab: false,
    },
  ],
};
export const QUICK_LINKS = {
  items: [
    {
      url: '/moving/',
      label: 'Move house',
      iconName: 'Move',
      newTab: false,
    },
    {
      url: '/pay',
      label: 'Make a payment',
      iconName: 'CreditCard',
      newTab: false,
    },
    {
      url: '/billing-payments',
      label: 'Billing & payments',
      iconName: 'Info',
      newTab: false,
    },
    {
      url: '/help-support',
      label: 'Help & support',
      iconName: 'Help',
      newTab: false,
    },
  ],
};

export const READY_TO_SWITCH = {
  sectionHeading: 'Ready to switch?',
  text: 'Make the hassle-free switch for great rates on your electricity, gas & internet.',
  links: [
    {
      text: 'Energy plans',
      url: '/electricity-gas/plans.html',
    },
    { text: 'Internet plans', url: '/internet/plans/' },
  ],
};

export const REWARDS_BENEFITS = {
  sectionHeading: 'Rewards & perks to boost your savings',
  items: [
    {
      description:
        'Boost your savings by collecting bonus sign-up points on the [Origin Everyday Rewards plan](/for-home/electricity-and-gas/plans/origin-everyday-rewards.html). Then collect ongoing points to save on a future shop. T&Cs apply.',
      heading: 'Collect Everyday Rewards points',
      image: '/homepage/home_edr_2x.png',
      label: 'Sign up today',
      accessibleLabel: '',
      url: '/for-home/electricity-and-gas/plans/origin-everyday-rewards.html',
    },
    {
      description:
        'Get fuel for less with Origin. You’ll save up to 10¢ a litre by combining with Woolworths and EG offers.\n\nSimply load the Origin app on your phone and present the offer at an [EG location](https://eg-australia.com/eg-store-locator/) to receive a discount on your fuel. \n[T&Cs apply](/terms-and-conditions/origin-fuel-offer-terms-and-conditions/).',
      heading: 'Get everyday savings on fuel',
      image: 'homepage/home_fuel_2x.png',
      label: 'Learn more',
      accessibleLabel: 'Learn more on how to save 10¢ a litre',
      url: '/for-home/campaign/origin-fuel-discount/',
    },
    {
      description:
        'Unlock bundle savings by having both energy and internet with us. \n\n With unlimited data on all plans and all speeds, Australia-based customer care specialists, and the freedom to change or cancel anytime. T&Cs apply',
      heading: 'Unlock discounts',
      image: 'homepage/home_internet_2x.png',
      label: 'Explore internet plans',
      accessibleLabel: '',
      url: '/internet/plans/',
    },
  ],
};

const TERMS_TEXT =
  '\\* **No lock-in contract** \\n' +
  'Origin’s residential energy plans do not have a minimum lock-in contract period or exit fees.';

export const TERMS = {
  heading: 'Important information',
  headingBold: true,
  text: TERMS_TEXT,
};

export const TRUST_PILOT = {
  sectionHeading: 'How customers rate their experience with us',
};

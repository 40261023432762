import React from 'react';

import { Heading, Text, Status, Button, Stack, Box } from '@origin-digital/ods-core';

import { IOffer } from 'src/models/content.interfaces';
import { kebabCase } from '../../utils/utils';

import { OFFERS_PREFIX } from '../../consts/tracking';
import { AccessibleLabel, AccessibleText } from '../Common/Link/Link.styles';
import { Card, Category } from './Offers.styles';
import { OfferImage } from './OfferImage';
import { CLOUDINARY_ENDPOINT } from '../../consts/config';

const cardBackgroundColour = (theme: string) => {
  switch (theme) {
    case 'orange':
      return 'primary';
    case 'purple':
      return ' rgba(140, 57, 245, 0.08)';
    case 'pink':
      return 'rgba(255, 105, 248, 0.16)';
    case 'yellow':
      return 'rgba(252, 252, 60, 0.32)';
    default:
      return 'rgba(255, 159, 0, 0.24)';
  }
};

export const Offer = ({
  accessibleLabel,
  category,
  tag,
  description,
  heading,
  image,
  imageAltText = '',
  label,
  url,
  terms,
  theme,
}: IOffer) => {
  const imageSrc = image && `${CLOUDINARY_ENDPOINT}/${image}`;
  const textTone = theme === 'orange' ? 'white' : 'neutralDark';
  // Split the heading into lines if it contains `\n`
  const headingLines = heading.includes('\n') ? heading.split('\n') : [heading];

  return (
    <Card
      data-id="offer-card"
      display="flex"
      flexDirection="column"
      textAlign="center"
      justifyContent="space-between"
      borderRadius="medium"
      backgroundColor={cardBackgroundColour(theme)}
      padding="xlarge"
      gap="medium"
    >
      <Stack space="medium">
        {(category || tag!.text) && (
          <Box paddingBottom="medium">
            {category ? (
              <Category>{category}</Category>
            ) : (
              <Status backgroundWeight="strong" tone={tag!.colour}>
                {tag!.text}
              </Status>
            )}
          </Box>
        )}

        {image && (
          <OfferImage dataId="offer-image--top" imageSrc={imageSrc} imageAltText={imageAltText} />
        )}
        {/* Render each line of the heading as a separate component */}
        <Stack space="none">
          {headingLines.map((line, index) => (
            <Heading
              key={index}
              data-id={`offer-heading-line-${index}`}
              component="h3"
              variant="h2"
              align="center"
              tone={textTone}
            >
              {line}
            </Heading>
          ))}
        </Stack>
        {description && (
          <Text data-id="offer-description" align="center" tone={textTone}>
            {description}
          </Text>
        )}
      </Stack>
      <Stack>
        <Button
          href={url}
          inverse={theme === 'orange'}
          data-id={`${OFFERS_PREFIX}-${kebabCase(label)}`}
        >
          {accessibleLabel ? (
            <>
              <AccessibleLabel aria-hidden="true">{label}</AccessibleLabel>
              <AccessibleText>{accessibleLabel}</AccessibleText>
            </>
          ) : (
            label
          )}
        </Button>
        {terms && (
          <Text data-id="offer-terms" variant="body-small">
            {terms}
          </Text>
        )}
      </Stack>
    </Card>
  );
};

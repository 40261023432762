import React from 'react';

interface IOfferImage {
  dataId: string;
  imageSrc: string;
  imageAltText: string;
}
export const OfferImage = ({ dataId, imageSrc, imageAltText }: IOfferImage) => (
  <img data-id={dataId} src={imageSrc} alt={imageAltText} />
);

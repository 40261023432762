import React from 'react';

// // Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Keyboard, Scrollbar, Navigation, Autoplay } from 'swiper';
import { COLOURS } from '../../../consts/style';

// import Swiper core and required modules
import { ArrowWrapper, Container } from './Slider.style';

// install Swiper modules
SwiperCore.use([Keyboard, Scrollbar, Navigation, Autoplay]);

const leftArrowPath = (
  <path fill={COLOURS.NEUTRALS.LIGHT} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
);
const rightArrowPath = (
  <path fill={COLOURS.NEUTRALS.LIGHT} d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
);

const Button = ({ id, right }: { id: string; right?: boolean }) => (
  <ArrowWrapper
    id={`swiper-${right ? 'next' : 'prev'}Btn-${id}`}
    role="button"
    aria-label={`${right ? 'view next slide' : 'view previous slide'}`}
    right={right}
  >
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ margin: '9px' }}
    >
      {right ? leftArrowPath : rightArrowPath}
    </svg>
  </ArrowWrapper>
);

type Props = {
  id: string;
  children: React.ReactElement;
  swiperProps: any;
};

export const Slider = ({ id, children, swiperProps }: Props) => (
  <Container className="slider-swiper">
    {swiperProps.navigation !== false && <Button id={id} />}
    {swiperProps.navigation !== false && <Button id={id} right />}
    <Swiper
      navigation={{
        nextEl: `.slider-swiper #swiper-nextBtn-${id}`,
        prevEl: `.slider-swiper #swiper-prevBtn-${id}`,
      }}
      {...swiperProps}
    >
      {children}
    </Swiper>
  </Container>
);

export const Slide = SwiperSlide;

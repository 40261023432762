import React from 'react';

import { Button, Heading, Text } from '@origin-digital/ods-core';

import { LinkVariant } from '../../models/content.interfaces';
import { TAIL_PREFIX } from '../../consts/tracking';
import { kebabCase } from '../../utils/utils';
import { Lamp, LampMobile, LinkContainer, Content } from './ReadyToSwitch.styles';

type Props = {
  sectionHeading: string;
  text: string;
  links: Array<{
    text: string;
    url: string;
    variant?: LinkVariant;
  }>;
};

export default ({ sectionHeading, text, links }: Props) => (
  <Content>
    <Lamp />
    <LampMobile />
    <Heading component="h3" variant="h2">
      {sectionHeading}
    </Heading>
    <Text>{text}</Text>

    <LinkContainer>
      {links.map((link) => (
        <Button href={link.url} key={link.text} data-id={`${TAIL_PREFIX}-${kebabCase(link.text)}`}>
          {link.text}
        </Button>
      ))}
    </LinkContainer>
  </Content>
);

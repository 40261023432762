import styled, { css } from 'styled-components';

import { Box } from '@origin-digital/ods-core';
import { BREAK_POINTS, COLOURS } from '../../consts/style';

const list = css`
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 16px;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    gap: 32px;
  }
`;

const item = css`
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  width: 253px;

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    width: calc(50% - 8px);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    width: calc(33.3333% - 8px);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.XL}) {
    width: ${(1108 - 32 * 2) / 3}px;
  }
`;

export const List = styled.ul`
  ${list}
`;

export const Item = styled.li`
  ${item}
`;

export const OffersWrapper = styled.div`
  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    margin: 0 auto;
  }

  // adjust Swiper controls for only offers
  // always visible on no-mobile (not just on hover)

  & .slider-swiper {
    overflow: visible;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      overflow: hidden;
    }
  }

  // offers only resizing
  @media screen and (max-width: ${BREAK_POINTS.Max.MD}) {
    & .swiper[data-id='swiper--with-scrollbar'] .swiper-scrollbar {
      left: 16px;
      width: calc(100% - 32px);
    }
  }

  #swiper-prevBtn-offers,
  #swiper-nextBtn-offers {
    margin-top: -${25 + 32}px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      opacity: 1;
    }
  }

  #swiper-prevBtn-offers {
    left: 12px;
  }
  #swiper-nextBtn-offers {
    right: 12px;
  }

  // swiper no-js fix
  & .swiper[data-id='swiper--with-scrollbar']:not(.swiper-initialized) .swiper-wrapper {
    & > .swiper-slide {
      margin-right: 32px;
      max-width: 350px;
    }
  }
`;

export const Card = styled(Box as any)`
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;

  & [data-id^='offer-image'] {
    width: 100%;
  }
`;

export const Category = styled.p`
  display: block;
  margin: 0 auto 16px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  color: ${COLOURS.NEUTRALS.DARK};
`;

import styled from 'styled-components';

import { BREAK_POINTS, COLOURS } from '../../consts/style';

export const ListItem = styled.li`
  & a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    border: 1px solid ${COLOURS.NEUTRALS.BORDERS};
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;
    min-height: 232px;
    // Design authorised cheat - look away
    padding: 16px 10px 32px 16px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      min-height: 295px;
      padding: 16px 16px 32px 16px;
    }

    @media screen and (min-width: ${BREAK_POINTS.Min.XL}) {
      padding: 24px 24px 32px 24px;
    }

    @media (prefers-reduced-motion: no-preference) {
      transition: box-shadow 0.25s ease;
    }
  }

  & a:hover,
  a:focus {
    box-shadow: rgb(0 0 0 / 8%) 0 0 16px 0;

    img[alt$='-icon'] {
      transform: scale(1.15);
    }
  }

  && h3 {
    /* allow \n to create a new line */
    white-space: pre-wrap;

    @media screen and (max-width: ${BREAK_POINTS.Max.SM}) {
      // Design authorised cheat - stop chevrons from wrapping
      font-size: 20px;
    }
  }

  & h3 svg {
    height: 20px;
    width: 20px;
    margin-left: 2px;
  }
`;

export const IconWrapper = styled.span`
  img {
    transform: scale(1);
    transition: transform 0.25s ease;
    height: 48px;
    width: 48px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      height: 64px;
      width: 64px;
    }
  }
`;

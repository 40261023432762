import styled from 'styled-components';

import { BREAK_POINTS, COLOURS } from '../../consts/style';

export const IconWrapper = styled.span``;

export const LabelWrapper = styled.span`
   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      justify-content: center;
      flex-direction: row;
      gap: 8px;
    }

    & svg {
      margin-right: 8px;
    }
  }
`;

export const ListItem = styled.li`
  overflow: hidden;
  flex-grow: 1;
  border-radius: 12px;

  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    flex: 1 1 calc(50% - 8px);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    flex: 1 1 calc(25% - 8px);
    border-radius: 16px;
  }

  & a {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    padding: 16px;
    color: ${COLOURS.NEUTRALS.DARK};
    background-color: rgba(255, 247, 235, 0.8);
    backdrop-filter: blur(8px);

    @media (prefers-reduced-motion: no-preference) {
      transition: background-color 0.5s ease;

      svg {
        transform: scale(1);
        transition: transform 0.25s ease;
        height: 1em;
        width: 1em;
        fill: currentColor;
      }
    }

    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      display: block;
      padding: 24px 0;
    }

    &:hover,
    &:focus {
      background-color: rgba(255, 247, 235, 1);

      svg {
        transform: scale(1.3);
      }
    }
  }
`;

import * as React from 'react';

import { Text } from '@origin-digital/ods-core';
import { IconBilling, IconCreditCard, IconHelp, IconMove } from '@origin-digital/ods-icons';
import { interaction } from '@origin-digital/reporting-client';

import { QUICKLINKS_PREFIX } from '../../consts/tracking';

import { IQuickLink } from '../../models/content.interfaces';
import { kebabCase, getTargetAttribute } from '../../utils/utils';
import { LabelWrapper, ListItem } from './QuickLink.styles';

const selectIcon = (iconName: string) => {
  switch (iconName) {
    case 'Move':
      return <IconMove size="small" variant="line" color="neutralDark" />;
    case 'Info':
      return <IconBilling size="small" variant="line" color="neutralDark" />;
    case 'CreditCard':
      return <IconCreditCard size="small" variant="line" color="neutralDark" />;
    default:
      return <IconHelp size="small" variant="line" color="neutralDark" />;
  }
};

export const QuickLink = ({ url, label, iconName, newTab }: IQuickLink) => {
  const onClickCapture = () => {
    interaction('click', {
      componentType: 'link',
      id: 'quicklinks',
      label: kebabCase(label),
    });
  };

  return (
    <ListItem>
      <a
        data-id={`${QUICKLINKS_PREFIX}-${kebabCase(label)}`}
        onClickCapture={onClickCapture}
        href={url}
        {...getTargetAttribute(newTab)}
      >
        <LabelWrapper>
          {selectIcon(iconName)}
          <Text tone="neutralDark">{label}</Text>
        </LabelWrapper>
      </a>
    </ListItem>
  );
};

import React from 'react';

import { interaction } from '@origin-digital/reporting-client';
import { Heading } from '@origin-digital/ods-core';

import { PRODUCTQUICKLINKS_PREFIX } from '../../consts/tracking';
import { IQuickLink } from '../../models/content.interfaces';
import { kebabCase, getTargetAttribute } from '../../utils/utils';
import { IconWrapper, ListItem } from './ProductQuickLink.styles';

const ICON_PATH = 'https://www.originenergy.com.au/cdn/images/originenergy/image/upload/icons/duo/';

// Using a custom icon - needs to be smaller than 24 viewBox
const CustomChevronIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.56381 2.81381C6.98224 2.3954 7.66062 2.3954 8.07905 2.81381L14.1289 8.86358C14.7564 9.49121 14.7564 10.5088 14.1289 11.1364L8.07905 17.1862C7.66062 17.6046 6.98224 17.6046 6.56381 17.1862C6.1454 16.7678 6.1454 16.0894 6.56381 15.671L12.2348 10L6.56381 4.32905C6.1454 3.91062 6.1454 3.23223 6.56381 2.81381Z"
      fill="currentColor"
    />
  </svg>
);

const selectIcon = (iconName: string) => {
  switch (iconName) {
    case 'Electricity':
      return <img src={`${ICON_PATH}Electricity-Gas--Custom--Duo.svg`} alt={`${iconName}-icon`} />;
    case 'Internet':
      return <img src={`${ICON_PATH}Internet--Custom--Duo.svg`} alt={`${iconName}-icon`} />;
    case 'Solar':
      return <img src={`${ICON_PATH}Solar--Custom--Duo.svg`} alt={`${iconName}-icon`} />;
    default:
      return <img src={`${ICON_PATH}LPG--Custom--Duo.svg`} alt={`${iconName}-icon`} />;
  }
};

const onClickCapture = (label: string) => {
  interaction('click', {
    componentType: 'link',
    id: 'product-quicklinks',
    label: kebabCase(label),
  });
};

export const ProductQuickLink = ({ url, label, iconName, newTab }: IQuickLink) => (
  <ListItem>
    <a
      data-id={`${PRODUCTQUICKLINKS_PREFIX}-${kebabCase(label)}`}
      onClickCapture={() => onClickCapture(label)}
      href={url}
      {...getTargetAttribute(newTab)}
    >
      <IconWrapper>{selectIcon(iconName)}</IconWrapper>
      <Heading component="h3" variant="h2">
        {label}
        <CustomChevronIcon />
      </Heading>
    </a>
  </ListItem>
);

import styled from 'styled-components';

import { BREAK_POINTS } from '../../consts/style';

export const Container = styled.div`
  padding-top: 64px;
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    margin-top: 0;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.XL}) {
    position: relative;
    z-index: 10;

    padding-top: 0;
    margin-top: -64px;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

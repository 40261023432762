import React from 'react';

import { ChevronLink, Heading, MarkdownLite, Stack, Text } from '@origin-digital/ods-core';

import { IRewardsBenefitsItem } from '../../models/content.interfaces';
import { AccessibleLabel, AccessibleText } from '../Common/Link/Link.styles';
import { REWARDS_PREFIX } from '../../consts/tracking';
import { kebabCase } from '../../utils/utils';

export const TextColumn = ({
  description,
  heading,
  label,
  accessibleLabel,
  url,
}: IRewardsBenefitsItem) => {
  return (
    <Stack>
      <Heading component="h3" variant="h2">
        {heading}
      </Heading>
      <Text>
        <MarkdownLite>{description}</MarkdownLite>
      </Text>
      <ChevronLink href={url} weight="medium" data-id={`${REWARDS_PREFIX}-${kebabCase(label)}`}>
        {accessibleLabel ? (
          <>
            <AccessibleLabel aria-hidden="true">{label}</AccessibleLabel>
            <AccessibleText>{accessibleLabel}</AccessibleText>
          </>
        ) : (
          label
        )}
      </ChevronLink>
    </Stack>
  );
};

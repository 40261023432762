import React from 'react';

import { ChevronLink, Heading, Stack, Text } from '@origin-digital/ods-core';

import { AppearUp } from '../transitions';
import { getCloudinaryUrl, kebabCase } from '../../utils/utils';
import { IApp, IImage } from '../../models/content.interfaces';
import { APPSTORE_PREFIX } from '../../consts/tracking';
import { AppWrapper, ContentWrapper, ImageWrapper } from './App.styles';

const ImageColumn = ({ image }: IImage) => {
  // using browser Browser-level image lazy-loading at this stage only images lazy-loaded
  // https://web.dev/browser-level-image-lazy-loading
  // https://caniuse.com/loading-lazy-attr

  // max image size with page is 554 - image resolution switching TBC with real images
  const defaultImageSrc = getCloudinaryUrl(image);
  const highResImageSrc = getCloudinaryUrl(image, true, 70, 1108);
  return (
    <ImageWrapper>
      <img
        srcSet={`${defaultImageSrc}, ${highResImageSrc} 2x`}
        src={highResImageSrc}
        alt="Example of Origin Energy App"
        loading="lazy"
      />
    </ImageWrapper>
  );
};

export const App = ({ heading, description, image, label, url }: IApp) => (
  <AppWrapper>
    <ImageColumn image={image} />

    <ContentWrapper>
      <Stack>
        <AppearUp>
          <Heading component="h3" variant="h2">
            {heading}
          </Heading>
        </AppearUp>
        <AppearUp>
          <Stack space="medium">
            {description.map((item) => (
              <Text key={item}>{item}</Text>
            ))}
          </Stack>
        </AppearUp>
        <AppearUp>
          <ChevronLink
            href={url}
            weight="medium"
            data-id={`${APPSTORE_PREFIX}-${kebabCase(label)}`}
          >
            {label}
          </ChevronLink>
        </AppearUp>
      </Stack>
    </ContentWrapper>
  </AppWrapper>
);
